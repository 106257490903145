<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <p class="">
          Download Report within your preferred date range
        </p>
        <b-row>
          <b-col
            cols="12"
            md="5"
            class="mb-md-0 mb-2"
          >
            <label for="start-date">From</label>
            <b-form-datepicker
              id="start-date"
              v-model="startDate"
              reset-button
              class="mb-2"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              @input="endDate=setEndDate(startDate, endDate)"
            />
          </b-col>
          <b-col
            cols="12"
            md="5"
            class="mb-md-0 mb-2"
          >
            <label for="end-date">To</label>
            <b-form-datepicker
              id="end-date"
              v-model="endDate"
              reset-button
              class="mb-2"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              @input="startDate=setStartDate(startDate, endDate)"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="align-self-center"
          >
            <div class="d-flex align-content-center">
              <b-button
                variant="primary"
                :disabled="isLoading"
                @click="getReports()"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                Download
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'
import calendar from '@/mixins/calendar'

export default {
  name: 'SalesReport',
  mixins: [helper, calendar],
  data() {
    return {
      isLoading: false,
      startDate: '',
      endDate: '',
      reports: {},
    }
  },
  methods: {
    getReports() {
      this.isLoading = true
      if (this.startDate && this.endDate) {
        api.getData(`b2e_dashboard/report/?start_date=${this.startDate}&end_date=${this.endDate}&export=true`, true)
          .then(response => {
            if (response.data.status) {
              this.reports = response.data.result
              if (response.data.result.export) {
                window.open(response.data.result.export)
              }
            } else {
              this.notificationMessage('warning', 'XIcon', 'Error', response.data.error.message)
            }
          }).catch().finally(() => {
            this.isLoading = false
          })
      } else {
        this.notificationMessage('warning', 'XIcon', 'Error', 'Please fill up date fields')
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>
